<template>
	<div
		class="size-px-16"
	>
		<div>

			<div class="mt-30-pc">
				<h4 class="color-primary">주문 상품 정보</h4>
				<div></div>
			</div>

			<template
				v-if="use_item.length > 0"
			>
				<ul class="mt-10 ">
					<li
						v-for="(item, item_index) in item_list"
						:key="item_index"
						class=" mb-10  bg-white "
					>
						<div
							v-if="false"
							class="pa-10 "
						><v-icon>mdi mdi-home-modern</v-icon>{{ item.company.seller_name }}</div>

						<div class="hide-pc mt-10 top-line">
							<div
								v-for="(product, product_index) in item.items"
								:key="'product_' + product_index"
								class="justify-space-between items-center gap-10 under-line ptb-10"
							>
								<div class="square-100"><img
									:src="product.pdt_img" alt="main1"
									@error="$bus.$emit('onErrorImage', $event)"
									class="object-cover width-100"
								/></div>
								<div class="flex-1 mr-10">
									<div class="font-weight-bold">{{ product.pdt_name }}</div>
									<div
										class="size-px-12 "
									>
										<div
											v-for="(option, index) in product.options"
											:key="'odt_option_' + index"
											class=""
										>
											<div class="color-gray mt-5">
												<span>{{ option.odt }}</span>
											</div>

											<div class="justify-space-between under-line-dashed pb-5">
												<span
												>{{ option.odt_price | makeComma }} 원</span>

												<span class="">수량 {{ option.odt_cnt }} 개</span>
											</div>
											<div class="text-right mt-10">
												<span class="font-weight-bold">{{ option.odt_cnt * option.odt_price | makeComma }} 원</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<table class="table hide-m">
							<colgroup>
								<col width="120px" />
								<col width="auto" />
								<col width="auto" />
								<col width="180px" />
								<col width="180px" />
								<col width="180px" />
							</colgroup>
							<thead>
								<tr>
									<th colspan="2">상품명</th>
									<th>옵션</th>
									<th>가격</th>
									<th>수량</th>
									<th>합계</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(product, product_index) in item.items"
									:key="'product_' + product_index"
									class=""
								>
									<td
									>
										<img
											:src="product.pdt_img" alt="main1"
											@error="$bus.$emit('onErrorImage', $event)"
											class="object-cover width-100"
										/>
									</td>

									<td class="td-left">{{ product.pdt_name }}</td>
									<td
									>
										<div
											v-for="(option, index) in product.options"
											:key="'odt_option_' + index"
											class=" pa-10 under-line-dashed"
										>
											<span>{{ option.odt }}</span>
										</div>
									</td>
									<td>
										<div
											v-for="(option, index) in product.options"
											:key="'odt_price_' + index"
											class=" pa-10 under-line-dashed"
										>
											<span
											>{{ option.odt_price | makeComma }} 원</span>

										</div>
									</td>
									<td>
										<div
											v-for="(option, index) in product.options"
											:key="'odt_cnt_' + index"
											class=" pa-10 under-line-dashed"
										>
											<span>{{ option.odt_cnt }} 개</span>
										</div>
									</td>
									<td>

										<div
											v-for="(option, index) in product.options"
											:key="'odt_cnt_' + index"
											class=" pa-10 under-line-dashed"
										>
											<span>{{ option.odt_cnt * option.odt_price | makeComma }} 원</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<div
							class="pa-10 flex-row justify-end gap-10 "
						>
							<span class="font-weight-bold">상품 합계</span>
							<span class="font-weight-bold"><span class="color-error font-weight-bold">{{ item.company.total_price | makeComma }}</span> 원</span>
						</div>
						<div
							v-if="false"
							class="pa-10 justify-space-between "
						>
							<span>
								배송비 <br/>
								<span class="size-px-11">{{ item.company.delivery }}</span>
							</span>
							<span>{{ item.company.delivery_price | makeComma }} 원</span>
						</div>
						<div
							v-if="is_island_delivery"
							class="pa-10 justify-space-between "
						>
							<div
							>
								도서/산간 추가배송비
							</div>
							<span>{{ item.company.island_price | makeComma }} 원</span>
						</div>
					</li>
				</ul>
			</template>
			<div
				v-else
				class="full-height flex-column justify-center"
			>
				<div class="text-center">No Data</div>
			</div>

			<div class="mt-30">
				<h4 class="color-primary">주문자 정보</h4>
				<table
					class="table td-left mt-10"
				>
					<colgroup class="hide-m">
						<col width="180px" />
						<col width="auto" />
					</colgroup>
					<colgroup class="hide-pc">
						<col width="80px" />
						<col width="auto" />
					</colgroup>
					<tbody>
						<tr
							class="mt-10 position-relative"
						>
							<th>이름</th>
							<td>
								<input
									v-model="item.member_name"
									class="input-box-inline"
									placeholder="이름"
									maxlength="25"
								/>
							</td>
						</tr>

						<tr
							class="mt-10 "
						>
							<th>연락처</th>
							<td>
								<input
									type="number"
									v-model="item.member_tell"
									class="input-box-inline"
									placeholder="연락처를 입력하세요"
									:rules="[$rules.max(item, 'member_tell', 15)]"
								/>
							</td>
						</tr>
						<tr
							class="mt-10 "
						>
							<th>이메일</th>
							<td>
								<input
									type="email"
									v-model="item.member_email"
									class="input-box-inline"
									placeholder="이메일주소를 입력하세요"
									:rules="[$rules.max(item, 'member_email', 50)]"
								/>
							</td>
						</tr>
						<tr>
							<th>주소</th>
							<td>
								<div
								>
									<input
										type="number"
										v-model="item.member_post"
										class="input-box-inline flex-3 mr-10 width-30-m"
										placeholder="우편번호"
										readonly
										:rules="[$rules.max(item, 'member_post', 7)]"
										@click="daumPost('default')"
									/>
									<button
										class="btn btn-default"
										@click="daumPost('default')"
									>주소 검색</button>
								</div>
								<div
								>
									<input
										v-model="item.member_addr1"
										type="text" placeholder="기본 주소"
										class="mt-10 input-box-inline width-40-pc width-90-m"
										readonly
										maxlength="80"
										@click="daumPost('default')"
									/>
								</div>
								<div class="mt-10">
									<label>
										<input
											v-model="item.member_addr2"
											type="text" placeholder="상세 주소를 입력하세요"
											class="input-box-inline width-40-pc width-90-m"
											maxlength="50"
										/>
									</label>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div
				v-if="false"
				class="mt-30"
			>
				<div
					class="justify-space-between"
				>
					<h4 class="flex-1">배송 구분</h4>
				</div>

				<div
					class="mt-10 bg-white pa-10 box-shadow"
				>
					<div
					>
						<label class="mr-10"><input v-model="item.delivery_type" type="radio" value="D002001" /> 택배 수령</label>
						<label><input v-model="item.delivery_type" type="radio" value="D002002" /> 방문 수령</label>
					</div>
				</div>

			</div>

			<div
				v-if="item.delivery_type == 'D002001'"
				class="mt-30"
			>
				<div
					class="flex-row justify-start gap-20 items-center"
				>
					<h4 class="color-primary">배송지 정보</h4>
					<div class="flex-column justify-center">

						<label
							class="size-em-07 mr-10"
						>
							<v-icon
								v-if="is_same"
								@click="is_same = false"
								class="size-px-20 color-blue"
							>mdi mdi-checkbox-marked</v-icon>
							<v-icon
								v-else
								@click="is_same = true"
								class="size-px-20 "
							>mdi mdi-checkbox-blank-outline</v-icon>
							주문자 정보와 동일
						</label>
					</div>
				</div>

				<table
					class="table td-left mt-10"
				>
					<colgroup class="hide-m">
						<col width="180px" />
						<col width="auto" />
					</colgroup>
					<colgroup class="hide-pc">
						<col width="80px" />
						<col width="auto" />
					</colgroup>

					<tbody>
						<tr
							v-show="false"
							class="mt-10 position-relative"
						>
							<th>배송지 명</th>
							<td>
								<input
									v-model="item.shipping_name"
									class="input-box-inline mr-10 width-30-m"
									placeholder="배송지 명"
									maxlength="25"
								/>
								<button
									class="btn-round btn-outline btn-ss hide-pc"
									@click="showSipping"
								>배송지 목록</button>

								<label
									class="size-em-09 inline-block width-90-m mt-5-m"
								>
									<v-icon
										v-if="!item.shipping_uid || item.shipping_uid == 'new'"
										@click="item.shipping_uid = 'not'"
										class="size-px-20 color-blue"
									>mdi mdi-checkbox-marked</v-icon>
									<v-icon
										v-else
										@click="item.shipping_uid = 'new'"
										class="size-px-20"
									>mdi mdi-checkbox-blank-outline</v-icon>
									신규 배송지로 등록
								</label>

								<label
									class="size-em-09 mr-10 inline-block width-90-m"
								>
									<v-icon
										v-if="item.is_base == '0'"
										@click="item.is_base = '1'"
										class="size-px-20"
									>mdi mdi-checkbox-blank-outline</v-icon>
									<v-icon
										v-else
										@click="item.is_base = '0'"
										class="size-px-20 color-blue"
									>mdi mdi-checkbox-marked</v-icon>
									기본 배송지로 사용
								</label>
								<button
									class="btn-round btn-outline btn-ss hide-m"
									@click="showSipping"
								>배송지 목록</button>
							</td>
						</tr>
						<tr
							class="mt-10 "
						>
							<th>이름</th>
							<td>
								<input
									v-model="item.d_name"
									class="input-box-inline"
									placeholder="이름"
									maxlength="25"
								/>
							</td>
						</tr>
						<tr
							class="mt-10 "
						>
							<th>연락처</th>
							<td>
								<input
									type="number"
									v-model="item.d_tell"
									class=" input-box-inline"
									placeholder="연락처"
									:rules="[$rules.max(item, 'd_tell', 15)]"
								/>
							</td>
						</tr>
						<tr>
							<th>주소</th>
							<td>
								<div
								>
									<input
										type="number"
										v-model="item.d_post"
										class="input-box-inline flex-3 mr-10 width-30-m"
										placeholder="우편번호"
										readonly
										:rules="[$rules.max(item, 'd_post', 7)]"
										@click="daumPost('order')"
									/>
									<button
										class="btn btn-default"
										@click="daumPost('order')"
									>주소 검색</button>
								</div>
								<div
								>
									<input
										v-model="item.d_addr1"
										type="text" placeholder="기본 주소"
										class="mt-10 input-box-inline width-40-pc width-90-m"
										readonly
										maxlength="80"
										@click="daumPost('order')"
									/>
								</div>
								<div class="mt-10">
									<label>
										<input
											v-model="item.d_addr2"
											type="text" placeholder="상세 주소를 입력하세요"
											class="input-box-inline width-40-pc width-90-m"
											maxlength="50"
										/>
									</label>
								</div>
							</td>
						</tr>
						<tr>
							<th>메모</th>
							<td>

								<input
									v-model="item.memo"
									type="text" placeholder="메모"
									class="input-box-inline width-40-pc width-90-m"
									maxlength="50"
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div
				v-else
				class="mt-10"
			>
				<div
					class="bg-white pa-10 box-shadow"
				>주문한 상품을 매장에 직접방문하여 수령합니다</div>
			</div>

			<div class="mt-30">
				<div class="justify-space-between items-center">
					<h4 class="color-primary">결제 정보</h4>
					<button
						class="btn-round btn-outline btn-ss-m hide-pc"
						@click="toPoint"
					>포인트 충전</button>
				</div>

				<div
					class="mt-10 pa-20 text-center top-line under-line bg-base"
				>
					<div
						v-if="is_business"
					>
						<button
							@click="item.pay_div = 'point'"
						>
							<v-icon
								v-if="item.pay_div == 'point'"
								class="color-green"

							>mdi mdi-radiobox-marked</v-icon>
							<v-icon
								v-else

							>mdi mdi-radiobox-blank</v-icon>
							<span class="ml-5"> 포인트 결제 </span>
						</button>
					</div>
					<div
						v-else
						class=" under-line-dashed pb-10 justify-center gap-10"
					>
						<div
							v-if="shop_info.is_bank == '1'"
							@click="item.pay_div = 'bank'"
						>
							<v-icon
								v-if="item.pay_div == 'bank'"
								class="color-green"
							>mdi mdi-radiobox-marked</v-icon>
							<v-icon
								v-else
							>mdi mdi-radiobox-blank</v-icon>
							<span> 무통장 입금</span>
						</div>
						<label

							v-for="(pg, pg_index) in items_pg"
							:key="'pg_' + pg_index"
							class="mt-10"
							@click="doPaymentCard(pg)"
						>
							<v-icon
								v-if="item.pay_div == pg.pay_div"
								class="color-green"
							>mdi mdi-radiobox-marked</v-icon>
							<v-icon
								v-else
							>mdi mdi-radiobox-blank</v-icon>
							<span> {{ pg.pay_div_name }}</span>
						</label>

						<div
							class="mt-10"
							v-if="is_do_bill"
							@click="doPaymentBill"
						>
							<v-icon
								v-if="item.pay_div == 'bill'"
								class="color-green"
							>mdi mdi-radiobox-marked</v-icon>
							<v-icon
								v-else
							>mdi mdi-radiobox-blank</v-icon>
							<span> 정기 결제</span>
						</div>
					</div>
					<div
						class="mt-10"
						v-if="item.pay_div == 'bank'"
					>
						<div>
							무통장 입금 안내
							<div class="mt-10 mb-10">{{ shop_info.bank_name }} {{ shop_info.bank_account }} {{ shop_info.bank_holder}}</div>

							<label
								class="mt-10 position-relative"
							>
								<input
									v-model="item.bank_info"
									placeholder="입금자 명"
									class=" input-box-inline"
								/>
							</label>
						</div>
					</div>
				</div>
				<div>
					<div
						v-if="false"
						class="justify-space-between under-line-dashed"
					>
						<span>총 배송비 </span>
						<span>{{ total_delivery_price | makeComma }} 원</span>
					</div>
					<div
						class="ptb-10 flex-row justify-space-between under-line"
					>
						<span
							class="font-weight-bold color-black"
						>결제 금액</span>
						<span
							class="order-price font-weight-bold color-blue"
						>{{ total_price | makeComma }} 원</span>
					</div>
					<div
						v-if="is_business"
						class="ptb-10 flex-row justify-space-between under-line items-center"
					>
						<span
							class="font-weight-bold color-black"
						>보유 포인트</span>
						<div>
							<span
								class="order-price font-weight-bold color-blue mr-10"
							>{{ item_point | makeComma }} Point <button @click="reload"><v-icon >mdi-refresh</v-icon></button></span>
							<button
								class="btn-round btn-outline btn-ss-m hide-m"
								@click="toPoint"
							>포인트 충전</button>
						</div>
					</div>
					<div
						v-if="is_business && this.total_price < this.min"
						class="ptb-20 under-line text-center color-red font-weight-bold"
					>
						포인트결제는 100만원이상 가능합니다
					</div>
				</div>
			</div>
		</div>

		<div
			class="mt-auto flex-column  position-relative pb-30"
		>
			<div
				class="mt-50 text-center "
			>
				<button
					class="btn btn-fill btn-s-m width-100-m"
					@click="checkPayment"
					:disabled="is_disabled"
				>
					<span class="color-white size-em-12">{{ order_price | makeComma }} 원 </span>
					<span class="color-white size-em-12"> 결제하기</span>
				</button>
			</div>
		</div>

		<DaumPost
			:overlay="daumPostUp"
			@callBack="addPost"
		></DaumPost>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"

			@close="is_modal = !is_modal"
		>
			<div slot="modal-content">
				<ul
					v-if="shipping_list.length > 0"
				>
					<li
						v-for="shipping in shipping_list"
						:key="shipping.uid"

						class="pa-10 box-shadow mb-10 bg-white"
					>
						<div
							class="ptb-10 justify-space-between under-line-dashed"
						>
						<span
							:class="{ 'color-green font-weight-bold': shipping.is_base == 1 }"
						>{{ shipping.shipping_name }}</span>
							<span>
							<v-icon
								class="color-blue"
								@click="setShipping(shipping)"
							>mdi mdi-arrow-right-bold-box-outline</v-icon>
						</span>
						</div>
						<div class="ptb-10  justify-space-between under-line-dashed">
							<span>{{ shipping.name }}</span>
							<span>{{ shipping.tell }}</span>
						</div>

						<div class="ptb-10">{{ shipping.post }} {{ shipping.addr1 }} </div>
						<div class="">{{ shipping.addr2 }}</div>
					</li>
				</ul>

				<div
					v-else
					class="full-height flex-column justify-center overflow-y-auto"
				>
					<div class="text-center">
						<p class="mt-20">등록된 주소록 정보가 없습니다.</p>
					</div>
				</div>
			</div>
		</Modal>

		<OrderFormPg
			v-if="(item.pay_div == 'card' || item.pay_div == 'phone') && is_order"
			v-show="true"

			:user="user"
			:order_info="order_item"
			:pg_info="pg_info"
			:is_mobile="is_mobile"

			@cancel="fail"
			@success="success"
			@fail="fail"
		>
		</OrderFormPg>
	</div>
</template>

<script>
import DaumPost from '@/components/Daum/DaumPost'
import Modal from "@/components/Modal";
import OrderFormPg from "./OrderFormPg";

export default{
	name: 'OrderForm'
	,props: ['user', 'codes', 'cart_info']
	,components: {OrderFormPg, Modal, DaumPost }
	,data: function(){
		return {
			program: {
				name: '주문하기'
				, top: false
				, title: true
				, bottom: true
				, from: 'Cart'
				, top_line: true
			}
			, daumPostType: 'default'
			,daumPostUp: false
			,order_number: ''
			,item: {
				pay_div: this.$route.name == 'OrderBill' ? 'bill' : 'card'
				,member_tell: this.user.member_tell ? this.user.member_tell : ''
				,member_email: this.user.member_email ? this.user.member_email : ''
				, member_name: this.user.member_name ? this.user.member_name : ''
				, member_post: this.user.member_post ? this.user.member_post : ''
				, member_addr1: this.user.member_addr1 ? this.user.member_addr1 : ''
				, member_addr2: this.user.member_addr2 ? this.user.member_addr2 : ''
				, d_name: ''
				, d_tell: ''
				, d_post: ''
				, d_addr1: ''
				, d_addr2: ''
				, site_bank: ''
				, bank_info: ''
				, c_uid: []
				, is_base: '0'
				, shipping_uid: 'new'
				, order_number: ''
				, pg_uid: ''
				, delivery_type: 'D002001'
			}
			,buy_items: [

			]
			,use_item: [
				{is_regular: ''}
			]
			,is_modal: false
			,modal_option: {
				title: '배송지 목록'
				,top: true
				,bottom: false
			}
			,shipping_list: [

			]
			,is_island_delivery: false
			, pg_list: []
			, pg_info: {}
			, is_order: false
			, is_mobile: this.$common.isMobile()
			, items_credit: []
			, item_credit: { bill_date: '', bill_rate: ''}
			, is_on_bill: false
			, items_bill_date: [5, 10, 15, 20, 25]
			, items_bill_rate: [3, 6, 12]
			, is_bill_result: false
			, is_on_corpay_m: false
			, is_on_wizz: false
			, item_point: 0
			, is_same: false
			, baseUrl: ''
			, min: 1000000
			, shop_info: {

			}
			, items_pg: []
			, item_pg: {}
		}
	}
	,computed: {
		order_price: function(){
			let price = 0
			price = this.total_price + this.total_delivery_price
			return price
		}
		,total_price: function(){
			let price = 0;

			for(let i = 0; i < this.use_item.length; i ++){
				if(!this.use_item[i].is_not_select) {
					price += (Number(this.use_item[i].pdt_price) + Number(this.use_item[i].op_price)) * this.use_item[i].op_cnt
				}
			}

			return price
		}
		,order_point: function(){
			let point = 0
			if(this.item.pay_div == 'point'){
				point = this.total_price
			}
			return point
		}
		,total_delivery_price: function(){
			let price = 0

			if(Object.keys(this.item_list).length > 0){
				for(const [key, val] of Object.entries(this.item_list)){
					if(!key){
						continue
					}
					price += Number(val.company.delivery_price)
					if (this.is_island_delivery) {
						price += Number(val.company.island_price)
					}
				}
			}

			return price
		}
		,order_item: function(){
			let item = this.item
			item.pay_div = this.item.pay_div
			item.total_price = this.total_price
			item.order_price = this.order_price
			item.order_point = this.order_point
			item.delivery_price = this.total_delivery_price
			item.site_bank = this.shop_info.bank_info
			item.c_uid = []
			item.order_number = this.item.order_number
			item.member_name = this.user.member_name
			item.member_email = this.item.member_email
			item.pg_uid = this.pg_info.uid
			item.pg_info = JSON.stringify(this.pg_info)

			let pdt_name = ''
			let pdt_code = ''
			if(Object.keys(this.item_list).length > 0) {
				for(const [key, val] of Object.entries(this.use_item)) {
					if(!key){
						continue
					}
					if(val.is_not_select){
						continue
					}
					pdt_name = val.pdt_name
					pdt_code = val.pdt_code
					item.c_uid.push(val.uid)
				}
			}

			item.pdt_name = pdt_name
			item.pdt_code = pdt_code

			item.c_uid = JSON.stringify(item.c_uid)

			return item
		}
		,item_list: function(){
			let items = {}

			for(const [key ,val] of Object.entries(this.use_item)){
				if(!key){
					continue
				}
				if(val.is_not_select){
					continue
				}
				let company = items[val.seller_id]

				if(!company){
					company = {
						company: {
							total_price: 0
						}
						,items: {}
					}
				}

				items[val.seller_id] = company

				items[val.seller_id]['company']['seller_id'] = val.seller_id
				items[val.seller_id]['company']['seller_name'] = val.shop_name
				items[val.seller_id]['company']['total_price'] += ((Number(val.pdt_price) + Number(val.op_price)) * val.op_cnt)
				items[val.seller_id]['company']['delivery_type'] = val.delivery_type
				items[val.seller_id]['company']['delivery_price'] = val.delivery_price
				items[val.seller_id]['company']['island_price'] = val.delivery_add_price

				if(val.delivery_type == '0'){
					items[val.seller_id]['company']['delivery_price'] = 0
					items[val.seller_id]['company']['delivery'] = ''
				}else{
					if(val.free_price > 0){
						items[val.seller_id]['company']['delivery'] = val.free_price + ' 이상 구매시 무료'
						if(val.free_price < items[val.seller_id]['company']['total_price']){
							items[val.seller_id]['company']['delivery_price'] = 0
						}
					}
				}

				let product = items[val.seller_id]['items'][val.pdt_uid]

				if(!product || product === undefined){
					product = {
						pdt_uid: val.pdt_uid
						,pdt_img: val.pdt_img1 ? val.pdt_img1 : ''
						,pdt_name: val.pdt_name
						,pdt_price: val.pdt_price
						,options: {}
					}
				}

				items[val.seller_id]['items'][val.pdt_uid] = product

				let option = items[val.seller_id]['items'][val.pdt_uid]['options'][val.uid]
				if(!option || option === undefined){
					option = {
						odt_uid: val.uid
						,odt: val.op_name
						,odt_cnt: val.op_cnt
						,odt_price: Number(val.pdt_price) + Number(val.op_price)
						,cart_index: key
						,is_not_select: val.is_not_select
					}
				}

				if(val.is_sold == 1 || (val.is_sold == 2 && val.pdt_stock < 1)){
					option.is_sold = true
					option.is_not_select = true
				}

				items[val.seller_id]['items'][val.pdt_uid]['options'][val.uid] = option
			}

			return items
		}
		, is_do_bill: function(){
			let t = false
			if(this.shop_info.is_bill == '1' && Number(this.shop_info.sales_bill_uid) && (this.$route.name == 'OrderBuy' || this.$route.name == 'OrderBill') && this.use_item[0].is_regular == '1'){
				t = true
			}
			return t
		}
		, is_business: function(){
			let t = false
			if(this.use_item[0].pdt_div == 'business'){
				t = true
			}
			return t
		}
		, is_disabled: function(){
			let t = true
			let d = ['member_name', 'member_tell', 'member_email', 'd_name', 'd_tell', 'd_addr1', 'd_addr2', 'd_post']

			if(this.is_business){
				if(this.total_price <= this.item_point){
					t = false
				}
			}else{
				t = false
			}
			d.forEach( (item) => {
				if(!this.item[item]){
					t = true
				}
			})
			// console.log(Number(this.total_price) , this.min)
			if(this.is_business){
				if(Number(this.total_price) < this.min){
					t = true
				}
			}
			return t
		}
		, cart_items: function(){
			let t = []
			this.cart_info.filter((item) => {

				if(item.is_use){
					t.push(item)
				}
			})
			return t
		}
	}
	,methods: {

		save: async function(){
			this.$bus.$emit('on', true)
			if(this.item.pay_div == 'bill'){
				this.order_item.credit_uid = this.item_credit.uid
				this.order_item.regular_date = this.item_credit.bill_date
				this.order_item.regular_rate = this.item_credit.bill_rate
			}
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'order/postCartOrder'
					,data: this.order_item
				})

				if(result.success){
					this.order_number_new = result.data.order_number_new
					if(this.item.pay_div == 'card' || this.item.pay_div == 'phone'){
						this.is_order = true
						this.$bus.$emit('on', true)
					}else{
						this.$bus.$emit('notify', { type: 'success', message: result.message})
						this.toResult()
					}

				}else{
					throw result.message
					//await this.toCancel(this.order_number_new)
				}
			}catch (e) {
				console.log(e)
				// await this.toCancel(this.order_number_new)
				this.$bus.$emit('notify', { type: 'error', message: e})
				this.is_order = false
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,daumPost: function (type) {
			this.$emit('setOverlay')
			this.daumPostUp = true
			this.daumPostType = type
		}
		, addPost: function (call) {

			if(this.daumPostType == 'default'){
				this.$set(this.item, 'member_post', call.zonecode)
				this.$set(this.item, 'member_addr1', call.address)
			}else{
				this.$set(this.item, 'd_post', call.zonecode)
				this.$set(this.item, 'd_addr1', call.address)
			}

			this.daumPostUp = false
			this.$emit('setOverlay')
		}
		,toResult: function(){
			this.$router.push({ name: 'OrderResult', params: { order_num_new: this.order_number_new }})
		}
		,getBuyItem: async function(){
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'order/getBuyItem'
				})

				if(result.success){
					this.use_item = result.data.result
					if(this.use_item.length <= 0){
						throw '주문 가능한 상품이 없습니다.'
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				this.$router.back()
			}
		}
		,getShippingList: async function(){
			try {
				const result = await this.$request.init({
					method: 'post'
					,url: 'member/getShippingList'
					,data: {
					}
				})
				if(result.success){
					this.shipping_list = result.data
					if(this.shipping_list.length > 0){
						this.item.shipping_name = result.data[0].shipping_name
						this.item.d_name = result.data[0].name
						this.item.d_tell = result.data[0].tell
						this.item.d_post = result.data[0].post
						this.item.d_addr1 = result.data[0].addr1
						this.item.d_addr2 = result.data[0].addr2
						this.item.shipping_uid = result.data[0].uid
					}
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}
		}
		,showSipping: function(){
			this.is_modal = true
		}
		,setShipping: function(shipping) {

			this.item.shipping_uid = shipping.uid
			this.item.shipping_name = shipping.shipping_name
			this.item.d_name = shipping.name
			this.item.d_tell = shipping.tell
			this.item.d_post = shipping.post
			this.item.d_addr1 = shipping.addr1
			this.item.d_addr2 = shipping.addr2
			this.item.is_base = shipping.is_base

			this.is_modal = false
		}

		, getShopPgInfo: async function(){
			try {
				const result = await this.$request.init({
					method: 'post'
					,url: 'order/getShopPgInfo'
					,data: {
					}
				})
				if(result.success){
					this.shop_info = result.data
					this.items_pg = this.shop_info.pg_list
					if(this.items_pg.length > 0){
						this.item.pay_div = this.items_pg[0].pay_div
						this.item_pg = this.items_pg[0]
						console.log(this.item_pg)
					}
				}else{
					if(!this.is_business){
						this.$bus.$emit('notify', { type: 'error', message: result.message})
						this.$router.back()
					}
				}
			}catch (e) {
				console.log(e)
			}
		}
		,getIslandDelivery: async function(post){

			let supply = []
			for(let key in this.item_list){
				supply.push(key)
			}

			try {
				const result = await this.$request.init({
					method: 'get'
					,url: 'order/getIslandDelivery'
					,data: {
						post: post
						,supply: supply
					}
				})
				if(result.success){
					this.is_island_delivery = result.data.is_island_delivery
					if(result.data.is_island_delivery){
						this.$bus.$emit('notify', { type: 'success', message: result.message})
					}
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}
		}

		, setPg: function(){

		}
		, getPgInfo: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'order/getPgInfo'
					,data: {
						order_price: this.order_price
						, pg_uid: this.item_pg.pg_uid
					}
				})
				if(result.success){
					this.pg_info = result.data
					await this.save()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getBillInfo: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'order/getBillInfo'
					,data: {
						order_price: this.order_price
					}
				})
				if(result.success){
					this.pg_info = result.data
					if(this.pg_info.pg_code == 'corpay_m'){
						this.is_on_corpay_m = true
					}else{
						this.is_on_bill = true
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getPgList: async function(){

			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'order/getPgList'
					,data: {
					}
				})
				if(result.success){
					this.pg_list = result.data
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getOrderNumber: async function(){

			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'order/getOrderNumber'
					,data: {
					}
				})
				if(result.success){
					this.$set(this.item, 'order_number', result.data)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		,do: async function(){

			if(this.$route.name != 'OrderForm'){
				await this.getBuyItem()
			}else{
				this.use_item = this.cart_items
				if(this.use_item.length <= 0){
					this.$bus.$emit('notify', { type: 'error', message: '주문 가능한 상품이 없습니다.'})
					this.$router.back()
				}
			}
			await this.getShopPgInfo()
			await this.getShippingList()

			await this.getOrderNumber()

			if(Number(this.shop_info.sales_bill_uid)){
				await this.getCreditList()
			}
		}
		, getCreditList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: '/order/getCreditList'
				})
				if(result.success){
					this.items_credit = result.data
				}else{
					throw result.message
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,setNotify: function({ type, message}){
			this.$bus.$emit('notify', { type: type, message: message})
		}
		,success: function(){
			this.update()
		}
		,fail: async function(){

			try{

				if(this.$route.name == 'OrderBuy'){
					await this.getBuyItem()
				}else{
					this.use_item = this.cart_items
					if(this.use_item.length <= 0){
						this.$router.back()
						throw '주문 가능한 상품이 없습니다.'
					}
				}

				await this.postOrderFail()

			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.pg_info = {}
				this.is_on_corpay_m = false
				this.$bus.$emit('notify', { type: 'error', message: '결제가 정상적으로 처리되지 않았습니다. 잠시후 다시 이용해주세요'})
				this.is_order = false
			}
		}
		, postOrderFail: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'order/postOrderFail'
					,data: {
						order_number: this.item.order_number
					}
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'error', message: '결제가 정상적으로 처리되지 않았습니다. 잠시후 다시 이용해주세요'})
					await this.do()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, update: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'order/postUpdateOrder'
					,data: {
						order_number: this.item.order_number
					}
				})
				if(result.success){
					this.toResult()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, toPayment: function(){
			if(this.item.pay_div == 'bank'){
				this.save()
			}else if(this.item.pay_div == 'card'){
				this.is_reappay = true
			}
		}
		,doPaymentCard: function(pg){
			this.item.pay_div = pg.pay_div
			this.item_pg = pg
		}
		, doPaymentBill: function(){
			this.item.pay_div = 'bill'
		}
		,toCancel: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'order/postFailOrderCancel'
					,data: {
						order_number: this.item.order_number
					}
				})
				if(result.success){
					window.location.reload()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, checkPayment: function(){
			if(this.item.pay_div == 'bank' || this.item.pay_div == 'point'){
				this.save()
			}else if(this.item.pay_div == 'bill') {
				this.getBillInfo()
			}else{
				this.getPgInfo()
			}
		}
		, postBill: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'order/postBill'
					,data: this.item_credit
				})
				if(result.success){
					this.is_bill_result = true
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setCredit: function(credit){
			let bill_date = this.item_credit.bill_date
			let bill_rate = this.item_credit.bill_rate
			this.item_credit = credit
			this.setBillDate(bill_date)
			this.setBillRate(bill_rate)
		}
		, setBillDate: function(bill_date){
			this.$set(this.item_credit, 'bill_date', bill_date)
		}
		, setBillRate: function(bill_rate){
			this.$set(this.item_credit, 'bill_rate', bill_rate)
		}
		, getMemberPoint: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'order/getMemberPoint'
				})
				if(result.success){
					this.item_point = result.data
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toPoint: function(){
			this.$bus.$emit('to', { name: 'PointItem'})
		}
		, reload: function(){
			document.location.reload()
		}
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)

		this.baseUrl = this.$baseUrl

		this.do()
	}
	,watch: {
		'item.d_post':{
			handler: function(call){
				this.getIslandDelivery(call)
			}
		}
		, is_business: {
			handler: function(call){
				if(call){
					this.item.pay_div = 'point'
					this.getMemberPoint()
				}
			}
		}
		, is_same: {
			handler: function(call){
				if(call){
					this.item.d_name = this.item.member_name
					this.item.d_tell = this.item.member_tell
					this.item.d_post = this.item.member_post
					this.item.d_addr1 = this.item.member_addr1
					this.item.d_addr2 = this.item.member_addr2
				}
			}
		}
	}
}
</script>

<style>

.pdt-cnt {
	padding: 10px;
}
.box-pdt-cnt {
}

.box-pdt-cnt > button {
	border: 1px solid #ddd;
	width: 100%;
	font-size: 20px;
}
.box-pdt-cnt > input {
	border: none;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	padding: 0px 5px;
	width: 100%;
	text-align: center;
}

.odt-img {
	margin-right: 10px
}
.odt-img img {width: 100% !important;}
</style>